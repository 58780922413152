<template>
  <v-card class="formCard">
    <v-toolbar density="comfortable" rounded="0" color="primary">
      <v-toolbar-title> <slot name="title">Title</slot></v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items>
        <v-btn v-if="!hideCancelButton" @click="$emit('cancel')" icon="mdi-close" />
      </v-toolbar-items>
    </v-toolbar>

    <v-divider></v-divider>

    <v-form ref="form" class="overflow-y-auto" @submit.prevent="save">
      <v-card-text class="py-4 text-center">
        <v-row no-gutters v-if="!loading">
          <v-col
            v-for="field in visibleFields"
            :key="field.name"
            cols="12"
            :sm="field.cols || '6'"
            :lg="field.cols || '4'"
            style="max-width: 100%"
            :class="[{ 'order-last': field.last }, 'flex-grow-1', 'flex-shrink-0', 'px-4']">
            <array-input
              v-if="field.type === 'array'"
              v-model="modelEdit[field.name]"
              :modelEdit="modelEdit"
              :field="field"
              :errors="validate({ ...field, graphQLErrors })"
              @validate="touch(field)">
            </array-input>
            <base-input
              v-else
              class="mb-2"
              v-model="modelEdit[field.name]"
              :apolloParams="{
                variables: field.variables ? field.variables(modelEdit) : {},
                skip: field.skip ? field.skip(modelEdit) : false,
                fetchPolicy: field.fetchPolicy ?? 'cache-first',
              }"
              :hint="field.hint ? field.hint(modelEdit) : undefined"
              :field="field"
              :errors="validate({ ...field, graphQLErrors })"
              :label="field.label || $t(`${model}.${field.name}`)"
              @validate="touch(field)">
            </base-input>
          </v-col>
        </v-row>
        <v-progress-circular v-else indeterminate :size="50" />
        <slot name="bottom"></slot>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions">
          <v-btn v-if="!hideCancelButton" @click="$emit('cancel')" color="secondary" variant="text">
            {{ $t("dialog.cancel") }}
          </v-btn>
          <v-btn v-if="!hideResetButton" @click="resetInfo()" color="warning" variant="text">
            {{ $t("dialog.reset") }}
          </v-btn>
          <v-btn :disabled="$v.$invalid" :loading="saving" type="submit" color="primary" variant="text">
            {{ saveBtnText || $t("dialog.save") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script setup lang="ts">
//IMPORTS
import { computed, onMounted, ref, watch } from "vue";

import BaseInput from "./inputs/BaseInput.vue";
import ArrayInput from "./inputs/ArrayInput.vue";
import { useI18n } from "vue-i18n";

import { useFormValueWrapper } from "../composable/formValueWrapper";

import useVuelidate from "@vuelidate/core";
import { useValidation } from "../composable/validation";

import { Field, BaseModel } from "../definitions";

//Props
const props = defineProps({
  currentValue: {
    type: Object,
    default: () => ({}),
  },
  defaultValue: {
    type: Object,
    default: () => ({}),
  },
  graphQLErrors: {
    type: Array<Object>,
    default: () => [],
  },
  fields: { type: Array<Field>, required: true },
  model: { type: String, required: true },
  saveBtnText: String,
  loading: Boolean,
  saving: Boolean,
  modelValue: Object,
  duplicating: Boolean,
  hideCancelButton: {
    type: Boolean,
    default: false,
  },
  hideResetButton: {
    type: Boolean,
    default: false,
  },
});
//Data
const modelEdit = ref({ ...props.defaultValue, ...props.currentValue });
const visibleFields = computed(() => props.fields.filter((field) => !field.isVisible || field.isVisible(modelEdit.value)));

//Validation
const rules = visibleFields.value
  .filter((field) => field.validation)
  .reduce((acc: BaseModel, field) => {
    acc[field.name] = field.validation;
    return acc;
  }, {});

//Composables
const $v = useVuelidate(rules, modelEdit);
const wrapValue = useFormValueWrapper();
const { validate } = useValidation($v, props.model);
const { t: $t } = useI18n();

//Emits
const emit = defineEmits(["update:modelValue", "save", "cancel"]);

function resetInfo() {
  modelEdit.value = JSON.parse(JSON.stringify({ ...props.defaultValue, ...props.currentValue }));
  if (props.duplicating) {
    props.fields
      .filter((field) => field.type == "file")
      .map((field) => field.name)
      .forEach((field) => delete modelEdit.value[field]);
  }
}

defineExpose({
  resetInfo,
});

const returnValue = computed(() => wrapValue(props.currentValue, modelEdit.value, props.fields, props.duplicating));

function updateModelValue() {
  if (props.duplicating) {
    // eslint-disable-next-line no-unused-vars
    const { id, __typename, ...duplicateValue } = returnValue.value;

    emit("update:modelValue", duplicateValue);
  } else emit("update:modelValue", returnValue.value);
}

watch(returnValue, updateModelValue);

//Mounted
onMounted(() => {
  updateModelValue();
  $v.value.$touch();
});

watch(
  () => props.currentValue,
  () => resetInfo()
);

watch(
  () => props.duplicating,
  () => resetInfo()
);

watch(visibleFields, () => $v.value.$touch());

function save() {
  $v.value.$touch();
  if (!$v.value.$invalid) emit("save");
}

function touch({ name, validation }: Field) {
  if (!validation) return;
  $v.value[name].$touch();
}
</script>

<script lang="ts">
export default {
  name: "BaseForm",
};
</script>

<style>
.formCard {
  width: clamp(16rem, 90vw, 70rem);
}
</style>
