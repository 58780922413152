<template>
  <ApolloQuery
    v-if="field.type == 'query'"
    :query="field.query"
    :variables="apolloParams.variables"
    :fetchPolicy="apolloParams.fetchPolicy"
    :update="field.update"
    :skip="apolloParams.skip">
    <template v-slot="{ result: { data, loading } }">
      <v-autocomplete
        :loading="loading"
        :items="!loading && data ? _.get(data, field.path) : []"
        :item-title="field.text || 'name'"
        :label="label"
        :menu-props="{
          'max-height': 300,
        }"
        :autocomplete="field.autocomplete || 'new-password'"
        :disabled="apolloParams.skip"
        :no-data-text="$t('no_results')"
        :error-messages="errors"
        :hint="hint"
        v-model="value"
        v-bind="field.bind"
        v-on="onProps"
        auto-select-first
        return-object />
    </template>
  </ApolloQuery>

  <ApolloQuery
    v-else-if="field.type == 'api' && (!field.skip || !field.skip())"
    :query="field.query"
    :variables="field.variables && field.variables()">
    <template v-slot="{ result: { data, loading } }">
      <v-combobox
        v-if="field.allow_unknown"
        :loading="loading"
        :items="!loading && data ? _.get(data, field.path) : []"
        :label="label"
        :no-data-text="$t('no_results')"
        :error-messages="errors"
        :autocomplete="field.autocomplete || 'new-password'"
        v-model="value"
        v-bind="field.bind"
        v-on="onProps" />
      <v-select
        v-else
        :loading="loading"
        :items="!loading && data ? _.get(data, field.path) : []"
        :autocomplete="field.autocomplete || 'new-password'"
        :no-data-text="$t('no_results')"
        :label="label"
        :error-messages="errors"
        item-title="name"
        item-value="id"
        v-model="value"
        v-bind="field.bind"
        auto-select-first
        v-on="onProps" />
    </template>
  </ApolloQuery>

  <datetime-input
    v-else-if="field.type === 'datetime' || field.type === 'date'"
    v-model="value"
    :flow="field.flow"
    :type="field.type"
    :label="label"
    :textFieldProps="{ 'prepend-icon': 'mdi-calendar', ...field.bind, 'error-messages': errors }" />

  <v-autocomplete
    v-else-if="field.type == 'autocomplete'"
    :label="label"
    :items="field.items"
    v-model="value"
    v-bind="field.bind"
    auto-select-first
    :no-data-text="$t('no_results')"
    :error-messages="errors"
    v-on="onProps" />

  <v-autocomplete
    v-else-if="field.type == 'time'"
    :label="label"
    :items="timeSlots"
    v-model="value"
    v-bind="field.bind"
    auto-select-first
    :error-messages="errors"
    :no-data-text="$t('no_results')"
    v-on="onProps" />

  <v-text-field
    v-else-if="field.type == 'number'"
    :label="label"
    v-bind="field.bind"
    type="number"
    v-model="value"
    :autocomplete="field.autocomplete || 'new-password'"
    :error-messages="errors"
    v-on="onProps" />

  <v-rating v-else-if="field.type == 'rating'" :label="label" v-bind="field.bind" v-model="value" :error-messages="errors" v-on="onProps" />

  <v-menu :close-on-content-click="false" v-else-if="field.type == 'color'" transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ props }">
      <v-text-field
        v-on="onProps"
        :label="label"
        v-bind="{ ...props, ...field.bind }"
        v-model="value"
        :autocomplete="field.autocomplete || 'new-password'"
        l="true"
        :error-messages="errors">
      </v-text-field>
    </template>
    <v-color-picker
      hide-canvas
      hide-inputs
      :swatches="swatches"
      show-swatches
      v-model="value"
      :label="label"
      :autocomplete="field.autocomplete || 'new-password'"
      v-on="onProps" />
  </v-menu>

  <v-textarea
    v-else-if="field.type == 'textarea'"
    :label="label"
    v-bind="field.bind"
    v-model="value"
    :autocomplete="field.autocomplete || 'new-password'"
    :error-messages="errors"
    v-on="onProps" />

  <v-file-input
    v-else-if="field.type === 'file'"
    :model-value="(value && [value]) ?? []"
    @update:model-value="$emit('update:modelValue', $event && $event[0])"
    :label="label"
    :rules="[checkFileSize]"
    :error-messages="errors"
    accept="image/*,.pdf"
    clearable />

  <v-checkbox v-else-if="field.type === 'checkbox'" v-bind="field.bind" v-model="value" :error-messages="errors" v-on="onProps">
    <template #label>
      <span v-html="label"></span>
    </template>
  </v-checkbox>

  <v-text-field
    v-else
    :label="label"
    v-bind="field.bind"
    v-model="value"
    :autocomplete="field.autocomplete || 'new-password'"
    :error-messages="errors"
    v-on="onProps" />
</template>

<script lang="ts">
export default {
  name: "BaseInput",
};
</script>

<script setup lang="ts">
import DatetimeInput from "./DatetimeInput.vue";
import { computed, ref, WritableComputedRef, watch } from "vue";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { useTimeslots } from "../../utils/timeslots";

const { t: $t } = useI18n();

const { getTimeSlots } = useTimeslots();

const swatches = ref([["#d11141"], ["#00b159"], ["#00aedb"], ["#f37735"], ["#ffc425"]]);
const props = defineProps({
  errors: Array<string>,
  label: String,
  apolloParams: { Object, default: () => ({ variables: {}, skip: false, fetchPolicy: "cache-first" }) },
  field: { type: Object, required: true },
  modelValue: { required: true },
  hint: String,
});

const value: WritableComputedRef<any> = computed({
  get: () => props.modelValue,
  set: (v) => emit("update:modelValue", v),
});

const emit = defineEmits(["validate", "update:modelValue"]);

const onProps = {
  blur: () => emit("validate"),
};

function checkFileSize(file: File) {
  if (!file) return true;

  if (file.size > 2e6) return $t("validation.file.size");
  else return true;
}

watch(
  () => props.apolloParams.variables,
  (newValue, oldValue) => {
    if (!_.isEqual(newValue, oldValue)) {
      emit("update:modelValue", null);
    }
  }
);

// watch(
//     () => props.apolloParams,
//     (newValue) => emit("update:modelValue", null)
// );

const timeSlots = computed(() => getTimeSlots("9:00", "23:45"));
</script>
