<template>
  <v-dialog v-model="display" :fullscreen="xs" :width="xs ? undefined : 'auto'">
    <template v-slot:activator="{ props }">
      <v-text-field
        ref="textfield"
        v-bind="{ ...textFieldProps, ...props }"
        :disabled="disabled"
        :loading="loading"
        :label="label"
        :model-value="formattedDatetime"
        readonly
      />
    </template>

    <v-card min-width="300">
      <v-tabs v-if="type == 'datetime'" fixed-tabs v-model="activeTab">
        <v-tab key="calendar">
          <slot name="dateIcon">
            <v-icon>mdi-calendar</v-icon>
          </slot>
        </v-tab>
        <v-tab key="timer" :disabled="dateNotSelected">
          <slot name="timeIcon">
            <v-icon>mdi-clock</v-icon>
          </slot>
        </v-tab>
      </v-tabs>
      <v-spacer></v-spacer>
      <v-window v-model="activeTab">
        <v-window-item key="calendar">
          <v-date-picker
            color="primary"
            :model-value="dayjs(modelValue).toDate()"
            @update:model-value="date = dayjs($event)"
            v-model:input-mode="input_mode"
            elevation="0"
            class="w-100"
            hide-actions
          ></v-date-picker>
        </v-window-item>
        <v-window-item class="my-1" key="timer" v-if="type == 'datetime'">
          <v-divider v-if="digital" />
          <v-expand-transition>
            <v-container v-if="digital">
              <v-select
                :label="$t('dialog.hour')"
                :model-value="time.hour()"
                @update:model-value="time = time.hour($event)"
                auto-select-first
                :items="hours"
              />
              <v-select
                hide-details
                :label="$t('dialog.minutes')"
                :model-value="time.minute()"
                @update:model-value="time = time.minute($event)"
                auto-select-first
                :items="minutes"
              />
            </v-container>
            <!-- <v-time-picker
              :allowed-minutes="(m) => m % 15 === 0"
              :allowed-hours="_range(9, 24)"
              ref="timer"
              v-else
              v-model="time"
              v-bind="timePickerProps"
              full-width
              format="24hr"></v-time-picker> -->
          </v-expand-transition>
        </v-window-item>
      </v-window>
      <v-spacer></v-spacer>
      <v-divider />
      <v-card-actions>
        <v-btn-toggle class="d-none" density="compact" v-if="activeTab == 1" v-model="digital">
          <v-btn size="small" icon><v-icon>mdi-clock</v-icon></v-btn>
          <v-btn size="small" icon><v-icon>mdi-clock-digital</v-icon></v-btn>
        </v-btn-toggle>
        <v-spacer></v-spacer>
        <slot name="actions">
          <v-btn color="secondary" variant="text" @click="clearHandler">{{
            $t("dialog.cancel")
          }}</v-btn>
          <v-btn color="primary" variant="text" @click="okHandler">{{
            activeTab == 0 && type == "datetime" ? $t("dialog.select_time") : $t("dialog.ok")
          }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import _range from "lodash/range";
import { ref, onMounted, computed, watch, PropType } from "vue";
import { useDayJS } from "@/bytenest-cli/composable/useDayJS";
import { useTimeslots } from "../../utils/timeslots";
import { useDisplay } from "vuetify";
const { dayjs } = useDayJS();
const { getTimeSlots } = useTimeslots();

const DEFAULT_DATE = dayjs();
const DEFAULT_TIME = dayjs().hour(9).minute(0).second(0);

const props = defineProps({
  modelValue: String,
  disabled: {
    type: Boolean,
  },
  loading: {
    type: Boolean,
  },
  label: {
    type: String,
    default: "",
  },
  type: { type: String as PropType<"date" | "datetime">, required: true },
  textFieldProps: {
    type: Object,
  },
});

const emit = defineEmits<{
  "update:modelValue": [string | null];
  selected: [string];
  blur: [];
}>();

const date = ref(dayjs());
const time = ref(DEFAULT_TIME);

const { xs } = useDisplay();

const datetime = computed(() => {
  const combinedDateTime = date.value
    .set("hour", time.value.hour())
    .set("minute", time.value.minute());
  if (props.type == "datetime") return combinedDateTime.format("YYYY-MM-DD HH:mm:ss");
  else return combinedDateTime.format("YYYY-MM-DD");
});

function init() {
  if (props.modelValue) {
    date.value = dayjs(props.modelValue);
    time.value = dayjs(props.modelValue);
  } else emit("update:modelValue", datetime.value);
}

onMounted(() => init());

const activeTab = ref(0);
function okHandler() {
  if (activeTab.value == 0 && props.type == "datetime") {
    activeTab.value = 1;
    return;
  }
  display.value = false;
  emit("update:modelValue", datetime.value);
  emit("selected", datetime.value);
}

function clearHandler() {
  date.value = DEFAULT_DATE;
  time.value = DEFAULT_TIME;
  display.value = false;
}

const formattedDatetime = computed(() => {
  if (!datetime.value) return "";
  if (props.type == "date") return dayjs(datetime.value).format("LL");
  else return dayjs(datetime.value).format("LLL");
});
const dateNotSelected = computed(() => !date.value);

const input_mode = ref<"calendar" | "keyboard">("calendar");
watch(
  () => datetime.value,
  () => {
    if (input_mode.value == "calendar") emit("update:modelValue", datetime.value);
  }
);

watch(
  () => props.modelValue,
  () => init()
);

const display = ref(false);
watch(
  () => display,
  (value) => {
    if (!value) emit("blur");
  }
);

const timeSlots = ref(getTimeSlots("9:00", "22:00", 5));
</script>

<script lang="ts">
export default {
  // datePickerProps: {
  //   type: Object,
  // },
  // timePickerProps: {
  //   type: Object,
  // },

  data() {
    return {
      digital: 1,
      hours: _range(7, 24).map((value) => ({ title: value.toString().padStart(2, "0"), value })),
      minutes: _range(0, 60, 5).map((value) => ({
        title: value.toString().padStart(2, "0"),
        value,
      })),
      selectedHour: null,
      selectedMinutes: null,
    };
  },
};
</script>
