import { useI18n } from "vue-i18n";

export declare type ValidationConfig = {
  name: string;
  graphQLErrors: Object[];
  validation?: any;
  label?: string;
};

export function useValidation($v, model: string): { validate: (config: ValidationConfig) => string[] } {
  const { t: $t } = useI18n();

  function validate(config: ValidationConfig): string[] {
    const { name, validation, label, graphQLErrors = [] } = config;
    const errors = gqlValidationErrors(name, graphQLErrors);

    const isArray: Boolean = $v.value[name]?.$each != undefined;

    if (isArray) {
      // if (!$v.value[name].$each.$response.$errors.length) return errors;
    } else if (!validation || !$v.value[name]?.$error) return errors;

    $v.value[name].$errors.forEach((error) => {
      const resolved = resolveError(error, label, name);
      if (resolved != null) errors.push(resolved);
    });

    if (errors.length === 0) errors.push("Unknown error");
    return errors;
  }

  function resolveError(error, label, name): string | null {
    //Required
    if (error.$validator === "required") return $t("form.validation.field_required", { field: label || $t(`${model}.${name}`) });
    //Email
    if (error.$validator === "email") return $t("form.validation.email", { field: label || $t(`${model}.${name}`) });
    //Field length
    if (error.$validator === "minLength") return $t("form.validation.min_length", { field: $t("user.password"), length: 6 });
    //Checkbox checked
    if (error.$validator === "checked") return $t("form.validation.checked");
    //Password mismatch
    if (error.$validator === "sameAsPassword") return $t("form.validation.password_mismatch", { field: label || $t(`${model}.${name}`) });
    //Password mismatch
    if (error.$validator === "minWords") return $t("form.validation.min_words", { field: label || $t(`${model}.${name}`), length: 2 });

    //Later date
    if (error.$validator === "laterThen") {
      return $t("form.validation.later_date", {
        field: label || $t(`${model}.${name}`),
        date: $t(`${model}.${$v.value[name].laterThen.$params.date}`),
      });
    }
    return null;
  }

  function gqlValidationErrors(name, graphQLErrors): Array<string> {
    const errors: string[] = [];
    graphQLErrors?.forEach((error) => {
      if (error.extensions?.category == "validation") {
        error.extensions.validation[`input.${name}`]?.forEach((validationError) => errors.push(formatGQLValidationError(validationError, name)));
      }
    });
    return errors;
  }

  function formatGQLValidationError(message: string, name: string): string {
    if (message.includes("taken")) return $t(`form.validation.unique`, { field: name });
    else return "Unknown GraphQLError";
  }

  return { validate };
}
