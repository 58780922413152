{
  "enrollment": {
    "latestInvoice": {
      "date": "Data ultimo pagamento",
      "description": "Descrizione ultimo pagamento",
      "payment_document": "Ultima pagamento"
    }
  },
  "invoice": {
    "name": "Pagamento|Pagamenti"
  }
}