{
  "add_student_profile": "Add student profile",
  "administrator": {
    "name": "Administrator"
  },
  "course": {
    "name": "Course"
  },
  "courseFrequency": {
    "code": "Code",
    "duration": "Duration",
    "name": "Attendance frequency"
  },
  "currently_enrolled": "Currently subscribed",
  "customer": {
    "name": "Customer"
  },
  "dialog": {
    "cancel": "cancel",
    "close": "close",
    "confirm": "confirm",
    "delete": "delete",
    "edit": "edit",
    "reset": "reset",
    "save": "save",
    "sure_to_delete": "Are you sure to delete this element?",
    "sure_to_skip": "Are tou sure to mark this lesson as skipped?",
    "undo_skip": "Reset the status of this skipped lesson?"
  },
  "enrollment": {
    "billableHours": "Billable hours",
    "billableLessonsCount": "#Lessons done",
    "compositeLessonsCount": "#Lessons",
    "filledInLessonsCount": "#Lessons filled in",
    "instrument": "Instrument",
    "latestInvoice": {
      "date": "Data ultima fattura",
      "description": "Descrizione ultima fattura",
      "payment_document": "Ultima fattura"
    },
    "lessonsCount": "#Lessons",
    "payment_method": "Payment method",
    "payment_recurrency": "Payment recurrency",
    "privacy": "Accept <a target='_blank' href='{link}'>privacy e policy</a>",
    "regulation": "Accept <a target='_blank' href='{link}'>regulation</a>",
    "skippedLessonsCount": "#Skipped lessons",
    "start": "Start",
    "start_timestamp": "Start at",
    "status": {
      "APPROVED": "Approved",
      "CLOSED": "Closed",
      "name": "Status",
      "PENDING": "Pending",
      "REJECTED": "Rejected"
    },
    "student": "Student",
    "subscription_fee": "Subscription fee",
    "teacher": "Teacher",
    "view_lessons": "View lessons"
  },
  "errors": {
    "Already authenticated": "Already authenticated, reload the page",
    "Email not verified": "Email not verified",
    "Invalid credentials": "Invlid credentials"
  },
  "form": {
    "validation": {
      "checked": "Check required",
      "email": "Invalid email",
      "field_required": "{field} is required.",
      "later_date": "{field} must be later then {date}.",
      "min_length": "{field} must have at least {length} characters",
      "min_words": "At least {length} words",
      "password_mismatch": "Passwords mismatch.",
      "unique": "This {field} is already in use"
    }
  },
  "group": {
    "name": "Group name"
  },
  "holidayRange": {
    "start": "Start"
  },
  "invoice": {
    "date": "Date",
    "description": "Description",
    "name": "Invoice|Invoices",
    "payment_document": "Payment document"
  },
  "lesson": {
    "name": "Lesson",
    "recover": "Recover",
    "status": {
      "ABSENT": "Absent",
      "name": "Status",
      "PENDING": "Pending",
      "PRESENT": "Present",
      "RECOVER": "Recover",
      "SKIPPED": "Skipped"
    },
    "timestamp": "Day and time"
  },
  "location": {
    "address": "Address",
    "name": "Location"
  },
  "no_results": "No results",
  "no_student_warning": {
    "dialog_text": "Please, insert a student profile before filling in the subscription",
    "dialog_title": "Missing student profile",
    "go_profile": "Visit profile",
    "text": "Fill the form once you set the date of the first lesson with the teacher"
  },
  "options": {
    "enrollment": {
      "payment_method": {
        "bank_transfer": "Bank transfer",
        "cash": "Cash at the front office"
      },
      "payment_recurrency": {
        "month": "Every month",
        "period": "One payment for each period",
        "year": "One time payment"
      }
    }
  },
  "page": {
    "dashboard": "Dashboard",
    "profile": "Profile",
    "student_profiles": "Student profiles",
    "subscription": "Subscription|Subscriptions"
  },
  "passwords": {
    "reset": "Password has been reset correctly",
    "sent": "We sent the reset link to {email}",
    "throttled": "You made to many request, wait some time",
    "user": "No user is linked to this email"
  },
  "signin": {
    "verify_again": "Verify"
  },
  "student": {
    "address": "Address",
    "age": "Age",
    "birth_place": "Birth place",
    "birth_province": "Birth province",
    "birthday": "Birthday",
    "cap": "CAP",
    "cf": "Tax code",
    "mobile": "Mobile phone",
    "name": "Full name",
    "nationality": "Nationality",
    "phone": "Phone",
    "residence": "Residence",
    "residence_province": "Residence province"
  },
  "teacher": {
    "availability": "Availability",
    "name": "Teacher|Teachers"
  },
  "user": {
    "email": "Email",
    "name": "Full name",
    "new_password": "New password",
    "password": "Password",
    "password_confirm": "Confirm password",
    "verify": "Verify"
  },
  "validations": {
    "checked": "Accettazione richiesta",
    "email": "Invalid email",
    "later_date": "{field} must be later then {date}.",
    "min_length": "{field} must have at least {length} characters",
    "min_words": "At least {length} words",
    "password_mismatch": "Passwords mismatch.",
    "required": "{field} is required.",
    "unique": "This {field} is already in use"
  },
  "weekPlanner": {
    "end": "End (excluded)",
    "overwrite": "Overwrite",
    "start": "Start (included)"
  }
}