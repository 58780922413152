import { createI18n } from "vue-i18n";
import _ from "lodash";

import { en as v_en, it as v_it } from "vuetify/locale";

import it from "../locales/it.json";
import en from "../locales/en.json";
import override_it from "../locales/override_it.json";
import override_en from "../locales/override_en.json";

if (import.meta.hot) {
  import.meta.hot.on("locales-update", () => {
    i18n.reloadResources().then(() => {
      i18n.changeLanguage(i18n.language);
    });
  });
}

const locale = localStorage.getItem("locale") || "it";

export const i18n = createI18n({
  legacy: false,
  fallbackLocale: "it",
  locale,
  messages: {
    en: { $vuetify: v_en, ..._.merge(en, override_en) },
    it: { $vuetify: v_it, ..._.merge(it, override_it) },
  },
});
