{
  "academicYear": {
    "calendar": "Calendario",
    "end": "Fine",
    "fees": "Quote di iscrizione",
    "name": "Anno accademico|Anni accademici",
    "start": "Inizio"
  },
  "add_student_profile": "Aggiungi profilo studente",
  "administrator": {
    "name": "Amministratore"
  },
  "course": {
    "name": "Corso"
  },
  "courseFrequency": {
    "code": "Codice",
    "duration": "Durata lezione",
    "name": "Frequenza"
  },
  "currently_enrolled": "Attualmente iscritto",
  "customer": {
    "address": "Indirizzo",
    "birth_place": "Luogo di nascita",
    "birth_province": "Provincia di nascita",
    "birthday": "Data di nascita",
    "cap": "CAP",
    "cf": "Codice fiscale",
    "mobile": "Cellulare",
    "name": "Cliente",
    "nationality": "Nazionalità",
    "phone": "Telefono",
    "residence": "Residenza",
    "residence_province": "Provincia di residenza"
  },
  "dialog": {
    "cancel": "annulla",
    "close": "chiudi",
    "confirm": "Conferma",
    "delete": "Elimina",
    "edit": "Modifica",
    "hour": "Ora",
    "minutes": "Minuti",
    "ok": "OK",
    "reset": "resetta",
    "save": "salva",
    "select_time": "seleziona orario",
    "sure_to_delete": "Sicuro di voler eliminare questo elemento?",
    "sure_to_skip": "Confermi di non aver svolto questa lezione?",
    "undo_skip": "Ripristinare lo stato di lezione svolta?"
  },
  "enrollment": {
    "billableHours": "Totale ore",
    "billableLessonsCount": "#Lezioni svolte",
    "compositeLessonsCount": "#Lezioni",
    "filledInLessonsCount": "#Lezioni compilate",
    "instrument": "Strumento",
    "latestInvoice": {
      "date": "Data ultima fattura",
      "description": "Descrizione ultima fattura",
      "payment_document": "Ultima fattura"
    },
    "lessonsCount": "#Lezioni",
    "name": "iscrizione|iscrizioni",
    "notes": "Note",
    "payment_method": "Metodo di pagamento",
    "payment_recurrency": "Modalità di pagamento",
    "pendingLessons": "Lezioni rimanenti",
    "pendingLessonsCount": "#Lezioni rimanenti",
    "privacy": "Accetto <a target='_blank' href='{link}'>privacy e policy</a>",
    "regulation": "Accetto <a target='_blank' href='{link}'>regolamento</a>",
    "reject_reason": "Verifiche necessarie",
    "skippedLessonsCount": "#Lezioni non svolte",
    "start": "Inizio",
    "start_timestamp": "Data e ora di inizio",
    "status": {
      "APPROVED": "Approvata",
      "CLOSED": "Conclusa",
      "name": "Stato",
      "PENDING": "Attesa di risposta",
      "REJECTED": "Da rivedere"
    },
    "student": "Studente",
    "subscription_fee": "Ricevuta quota di iscrizione",
    "teacher": "Insegnante",
    "view_lessons": "Vedi lezioni"
  },
  "errors": {
    "Already authenticated": "Sei già autenticato, ricarica la pagina",
    "Email not verified": "Email non verificata",
    "Invalid credentials": "Credenziali non valide"
  },
  "export": "Export|Export",
  "form": {
    "validation": {
      "checked": "Accettazione richiesta",
      "email": "L'email non è valida",
      "field_required": "Il campo {field} è obbligatorio.",
      "later_date": "{field} deve essere successivo a {date}.",
      "min_length": "{field} deve avere almeno {length} caratteri",
      "min_words": "Numero minimo di parole: {length}",
      "password_mismatch": "Le password non coincidono.",
      "unique": "Questo {field} è già stato utilizzato"
    }
  },
  "group": {
    "name": "Nome classe"
  },
  "holidayRange": {
    "end": "Fine",
    "mandatory": "Forzato",
    "name": "Periodo di chiusura|Periodi di chiusura",
    "start": "Inizio"
  },
  "invoice": {
    "date": "Data",
    "description": "Descrizione",
    "name": "Fattura|Fatture",
    "payment_document": "Documento di pagamento"
  },
  "lesson": {
    "name": "Lezione",
    "recover": "Recupero",
    "status": {
      "ABSENT": "Assente",
      "name": "Stato",
      "PENDING": "Attesa di registrazione",
      "PRESENT": "Presente",
      "RECOVER": "Recupero",
      "SKIPPED": "Non svolta"
    },
    "timestamp": "Data e ora"
  },
  "location": {
    "address": "Indirizzo",
    "name": "Sede"
  },
  "no_results": "Nessun risultato",
  "no_student_warning": {
    "dialog_text": "Procedi con l'iscrizione dopo aver completato i profili studente",
    "dialog_title": "Profilo studente mancante",
    "go_profile": "Vai a profilo",
    "text": "Compila questo form dopo aver concordato con il docente la data della prima lezione"
  },
  "options": {
    "enrollment": {
      "payment_method": {
        "bank_transfer": "Bonifico Bancario",
        "cash": "Contanti recandosi in Segreteria"
      },
      "payment_recurrency": {
        "month": "Quota del periodo rateizzata mensilmente",
        "period": "Quota del periodo",
        "year": "Quota annuale"
      }
    }
  },
  "page": {
    "dashboard": "Dashboard",
    "profile": "Profilo",
    "student_profiles": "Profili studente",
    "subscription": "Iscrizione|Iscrizioni"
  },
  "passwords": {
    "reset": "La password è stata resettata correttamente",
    "sent": "Abbiamo inviato il link di reset all'indirizzo {email}",
    "throttled": "Hai fatto troppe richieste di reset, attenti qualche minuto",
    "user": "Nessun account associato a questa mail"
  },
  "signin": {
    "verify_again": "Verifica"
  },
  "student": {
    "address": "Indirizzo",
    "age": "Età",
    "birth_place": "Luogo di nascita",
    "birth_province": "Provincia di nascita",
    "birthday": "Data di nascita",
    "cap": "CAP",
    "cf": "Codice fiscale",
    "mobile": "Cellulare",
    "name": "Nome e cognome studente|Studenti",
    "nationality": "Nazionalità",
    "phone": "Telefono",
    "residence": "Residenza",
    "residence_province": "Provincia di residenza"
  },
  "table": {
    "actions": ""
  },
  "teacher": {
    "availability": "Disponibilità oraria",
    "name": "Insegnante|Insegnanti"
  },
  "user": {
    "email": "Email",
    "name": "Nome e cognome",
    "new_password": "Nuova password",
    "password": "Password",
    "password_confirm": "Conferma password",
    "verify": "Verifica"
  },
  "validations": {
    "checked": "Accettazione richiesta",
    "email": "L'email non è valida",
    "later_date": "{field} deve essere successivo a {date}.",
    "min_length": "{field} deve avere almeno {length} caratteri",
    "min_words": "Numero minimo di parole: {length}",
    "password_mismatch": "Le password non coincidono.",
    "required": "Il campo {property} è obbligatorio.",
    "unique": "Questo {field} è già stato utilizzato"
  },
  "weekPlanner": {
    "end": "Fine (esclusa)",
    "overwrite": "Sovrascrivi date",
    "start": "Inizio (incluso)"
  }
}