import { createRouter, createWebHistory } from "vue-router";
import { isLoggedIn } from "../utils/login";

const UNGUARDED_PAGES = ["Login", "ForgotPassword", "Register", "VerifyAgain", "VerifyMail"];

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Dashboard",
      component: () => import("../pages/Dashboard.vue"),
    },
    {
      path: "/newenrollment",
      name: "NewEnrollment",
      component: () => import("../pages/NewEnrollment.vue"),
    },
    {
      path: "/student/:student_id",
      name: "Student",
      component: () => import("../pages/Student.vue"),
      props: true,
    },
    {
      path: "/enrollments/:status?",
      name: "Enrollments",
      props: (route: { params: { status: string } }) => ({ status: route.params.status?.toUpperCase() }),
      component: () => import("../pages/Enrollments.vue"),
    },
    {
      path: "/profile",
      name: "Profile",
      component: () => import("../pages/Profile.vue"),
    },
    {
      path: "/my-students",
      name: "MyStudents",
      component: () => import("../pages/MyStudents.vue"),
    },
    {
      path: "/customer/:id",
      name: "Customer",
      component: () => import("../pages/Customer.vue"),
      props: true,
    },
    {
      path: "/teacher/:id",
      name: "Teacher",
      component: () => import("../pages/Teacher.vue"),
      props: (route) => ({ id: route.params.id }),
      children: [
        {
          path: "",
          name: "TeacherRecap",
          component: () => import("../components/teacher/TeacherRecap.vue"),
        },
        {
          path: "calendar",
          name: "TeacherCalendar",
          component: () => import("../components/teacher/Calendar.vue"),
        },
        {
          path: "week-planner",
          name: "TeacherWeekPlanner",
          component: () => import("../components/teacher/WeekPlanner.vue"),
        },
        {
          path: "recovers",
          name: "TeacherRecovers",
          component: () => import("../components/teacher/Recovers.vue"),
        },
        {
          path: "hours",
          name: "TeacherHours",
          component: () => import("../components/teacher/Hours.vue"),
          children: [
            {
              path: "enrollment/:enrollment_id",
              name: "TeacherHoursEnrollment",
              component: () => import("../components/teacher/EnrollmentLessonsTable.vue"),
              props: (route) => ({ enrollment_id: route.params.enrollment_id }),
            },
            {
              path: "group/:group_id",
              name: "TeacherHoursGroup",
              component: () => import("../components/teacher/GroupLessons.vue"),
              props: (route) => ({ group_id: route.params.group_id }),
            },
          ],
        },
      ],
    },
    {
      path: "/students",
      name: "Students",
      component: () => import("../pages/Students.vue"),
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("../pages/Login.vue"),
    },
    {
      path: "/verifymail/:token",
      name: "VerifyMail",
      component: () => import("../pages/VerifyMail.vue"),
      meta: { auth: false },
    },
    {
      path: "/verifyagain/:email?",
      name: "VerifyAgain",
      component: () => import("../pages/VerifyAgain.vue"),
      props: true,
      meta: { auth: false },
    },
    {
      path: "/forgot-password/:email?/:token?",
      name: "ForgotPassword",
      component: () => import("../pages/ForgotPassword.vue"),
      props: true,
      meta: { auth: false },
    },
    {
      path: "/register",
      name: "Register",
      component: () => import("../pages/Register.vue"),
    },
    {
      path: "/calendar",
      name: "Calendar",
      component: () => import("../pages/Calendar.vue"),
    },
    {
      path: "/holiday-ranges",
      name: "HolidayRanges",
      component: () => import("../pages/HolidayRanges.vue"),
    },
    {
      path: "/academic-years",
      name: "AcademicYears",
      component: () => import("../pages/AcademicYears.vue"),
    },
    {
      path: "/week-planner",
      name: "WeekPlanner",
      component: () => import("../pages/WeekPlanner.vue"),
    },
    {
      path: "/recovers",
      name: "Recovers",
      component: () => import("../pages/Recovers.vue"),
    },
    {
      path: "/hours",
      name: "Hours",
      component: () => import("../pages/Hours.vue"),
      children: [
        {
          path: "enrollment/:enrollment_id",
          name: "HoursEnrollment",
          component: () => import("../components/teacher/EnrollmentLessonsTable.vue"),
          props: true,
        },
        {
          path: "group/:group_id",
          name: "HoursGroup",
          component: () => import("../components/teacher/GroupLessons.vue"),
          props: true,
        },
      ],
    },
    {
      path: "/groups",
      name: "Groups",
      component: () => import("../pages/Groups.vue"),
    },
    {
      path: "/enrollment-requests",
      name: "EnrollmentRequests",
      component: () => import("../pages/EnrollmentRequests.vue"),
    },
    {
      path: "/users/:type?",
      name: "Users",
      component: () => import("../pages/Users.vue"),
      props: true,
    },
    {
      path: "/enrollments-invoicing",
      name: "EnrollmentsInvoicing",
      component: () => import("../pages/invoicing/EnrollmentsInvoicing.vue"),
      props: true,
    },
    {
      path: "/my-enrollments-invoicing",
      name: "MyEnrollmentsInvoicing",
      component: () => import("../pages/invoicing/MyEnrollmentsInvoicing.vue"),
    },
    {
      path: "/enrollment-invoicing/:enrollment_id",
      name: "EnrollmentInvoicing",
      component: () => import("../pages/invoicing/EnrollmentInvoicing.vue"),
      props: true,
    },
    {
      path: "/exports",
      name: "Exports",
      component: () => import("../pages/Exports.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.name && !UNGUARDED_PAGES.includes(to.name.toString()) && !isLoggedIn()) {
    next({ name: "Login", query: { redirect: to.fullPath } });
  } else if (to.name && UNGUARDED_PAGES.includes(to.name?.toString()) && isLoggedIn()) {
    next({ name: "Dashboard" });
  } else {
    next();
  }
});

export default router;
